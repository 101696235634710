import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { SpinnerCircular } from 'spinners-react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import 'yup-phone';
import * as Sentry from '@sentry/gatsby';

import Favicon from '../images/favicon.ico';
import Layout from '../components/Layout';

const contactSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required!'),
  phone: Yup.string().required('Le numéro de téléphone est requis'),
  // .phone(
  //   'CH',
  //   true,
  //   'Numéro de téléphone invalide (seuls les numéros venant de Suisse sont autorisés)',
  // ),
  message: Yup.string()
    .required('Le message est requis')
    .min(6, 'Votre message est trop court')
    .max(200, 'Votre message est trop long'),
});
const Contact = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    Sentry.captureMessage('Form submitted');
    const { email, phone, message } = values;
    setLoading(true);
    const data = JSON.stringify({
      email,
      phone,
      message,
    });
    fetch(`https://super-serrurier.vercel.app/api/sendmail`, {
      method: 'POST',

      body: data,
    })
      .then((response) => {
        if (response.status === 200) {
          setSubmitting(false);
          setLoading(false);
          navigate('/thank-you');
        } else {
          setSubmitting(false);
          setLoading(false);
          Sentry.captureMessage('[!200 /api/sendmail]:', response);
        }
      })
      .catch((err) => {
        Sentry.captureMessage('[Error /api/sendmail]:', err);
        setSubmitting(false);
        setLoading(false);
      });
  };

  return (
    <Layout>
      <Helmet>
        <link rel="icon" href={Favicon} />
        <meta charSet="utf-8" />
        <title>Contactez Super Serrurier - devis gratuit 24/7</title>
        <meta
          name="description"
          content="Dépannage d’urgence, problème de porte, de clés ou encore un devis sur des travaux de serrurerie. Contactez-nous, nous sommes disponible 24/7"
        />
      </Helmet>

      <div className="bg-white">
        <div className="max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h1 className="text-2xl font-extrabold tracking-tight text-primarycolor-light sm:text-3xl">
                Contactez-nous
              </h1>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                Nous sommes joignables par téléphone 7j/7 et 24h/24 Nous
                répondons par e-mail Du lundi au vendredi de 8h à 17h
              </p>
              <dl className="mt-8 text-base text-gray-600">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>Super Serrurier, Rue Saint-Martin 38,</p>
                    <p>1005 Lausanne</p>
                  </dd>
                </div>
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      ></path>
                    </svg>
                    <a href="tel:0791304768" className="ml-3">
                      {' '}
                      079 130 47 68
                    </a>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      ></path>
                    </svg>
                    <a
                      href="mailto:clients@super-serrurier.ch"
                      className="ml-3"
                    >
                      {' '}
                      clients@super-serrurier.ch{' '}
                    </a>
                  </dd>
                  <div class="mt-6 relative mb-4">
                    <div
                      class="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div class="w-1/2 border-t border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-center text-sm">
                      <span class="px-2 bg-white text-gray-500"></span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <h4 className="text-lg text-gray-500 font-extrabold">
                      Horaires d'ouverture et d’intervention
                    </h4>
                    <div>
                      <DayWrapper>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <p>lundi</p>
                        </div>

                        <p>Ouvert 24/24</p>
                      </DayWrapper>
                      <DayWrapper>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <p>mardi</p>
                        </div>

                        <p>Ouvert 24/24</p>
                      </DayWrapper>
                      <DayWrapper>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <p>lundi</p>
                        </div>

                        <p>Ouvert 24/24</p>
                      </DayWrapper>
                      <DayWrapper>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <p>jeudi</p>
                        </div>

                        <p>Ouvert 24/24</p>
                      </DayWrapper>
                      <DayWrapper>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <p>vendredi</p>
                        </div>

                        <p>Ouvert 24/24</p>
                      </DayWrapper>
                    </div>
                  </div>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <Formik
                initialValues={{ email: '', phone: '', message: '' }}
                validationSchema={contactSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="grid grid-cols-1 gap-y-6">
                    <div>
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <Field
                        required
                        name="email"
                        type="email"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        placeholder="Email"
                      />
                      <ErrorMessage
                        className="text-red-500 text-sm mt-2"
                        name="email"
                        component="div"
                      />
                    </div>
                    <div>
                      <label htmlFor="phone" className="sr-only">
                        Phone
                      </label>
                      <Field
                        required
                        type="text"
                        name="phone"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        placeholder="Phone"
                      />
                      <ErrorMessage
                        className="text-red-500 text-sm mt-2"
                        name="phone"
                        component="div"
                      />
                    </div>
                    <div>
                      <label htmlFor="message" className="sr-only">
                        Message
                      </label>
                      <Field
                        required
                        as="textarea"
                        name="message"
                        rows="4"
                        className="block resize-none w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        placeholder="Message"
                      />
                      <ErrorMessage
                        className="text-red-500 text-sm mt-2"
                        name="message"
                        component="div"
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primarycolor hover:bg-primarycolor-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={isSubmitting}
                      >
                        Envoyer
                        <SpinnerCircular
                          enabled={loading}
                          style={{ color: '#fff', marginLeft: '5px' }}
                          size={25}
                        />
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="relative text-right w-full h-96">
        <div className="overflow-hidden bg-none w-full h-full">
          <iframe
            title="Super serrurier lausanne"
            className="relative text-right w-full h-full"
            src="https://maps.google.com/maps?q=Rue%20Saint-Martin%2038%2C%20%201005%20Lausanne&amp;t=&amp;z=7&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          ></iframe>
          {/* <a href="https://embedgooglemap.net/139/"></a> */}
        </div>
      </div>
    </Layout>
  );
};

const DayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 215px;
  margin-top: 1.5rem;

  & div {
      display: flex;
    align-items: center;
 }
  & svg {
      margin-right: 8px;
      width: 30px;
  }
  }
`;
export default Contact;
